.main-form {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 10px;
}

.main-item-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.position-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.single-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right-items {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    width: 50rem;
}

.right-items-bottom {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.preview-section {
    background-color: #2b2a44;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    padding: 2rem;
    gap: 18rem;
}


.left-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 2rem;
}

.right-side {
    width: 50%;
}

.x {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.y {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.z {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-heading {
    margin: 17px 0px;
}

.inner-main-headings {
    margin-bottom: 28px;
}

.inner-main-subheadings {
    margin-bottom: 15px;
}

.inner-p {
    margin-bottom: 15px;
}

.controlls-submit-btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiButton-root {
    backGround: #2b2a44 !important
}

.input-mui {
    color: white !important
}

.MuiInputLabel-root {
    color: rgb(255, 255, 255) !important;
}

.MuiInputBase-input {
    color: rgb(255, 255, 255) !important;
}

.MuiTextFieldNumer::-webkit-inner-spin-button,
.MuiTextFieldNumer::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.preview-items-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}