.btn-bkg {
    justify-content: center;
    align-items: center;
    display: flex;
    background: #21232e;
    height: 150px;
    width: 150px;
    border-radius: 100%;
    border: 2px solid white;
}

.btn-main-circle {
    height: 26px;
    width: 26px;
    background: white;
    border-radius: 100%;
    z-index: 100;
}

.btn-star {
    height: 53px;
    width: 53px;
    position: absolute;
    background: #3e424e;
    clip-path: polygon(50% 0,
            34% 34%,
            0 50%,
            34% 68%,
            50% 100%,
            68% 68%,
            100% 50%,
            68% 34%);
}

.btn-up {
    position: absolute;
    margin-bottom: 7rem;
    color: #3e424e;
}

.btn-down {
    position: absolute;
    margin-top: 7rem;
    color: #3e424e;
}

.btn-right {
    position: absolute;
    margin-left: 7rem;
    color: #3e424e;
}

.btn-left {
    position: absolute;
    margin-right: 7rem;
    color: #3e424e;
}